.file-input-container {
    display: flex;
    flex-direction: column;
    margin-bottom: 1rem;
    max-width: 350px;
}

.file-input-label {
    margin-bottom: 0.5rem;
    font-size: 0.875rem;
    font-weight: 500;
    color: #1f2937; /* dark gray color */
}

.file-input {
    width: 100%;
    font-size: 0.875rem;
    color: #1f2937; /* dark gray color */
    border: 1px solid #d1d5db; /* light gray border */
    border-radius: 0.5rem; /* rounded corners */
    cursor: pointer;
    background-color: #f9fafb; /* light gray background */
    padding: 0.5rem; /* some padding */
    outline: none;
}

.file-input:focus {
    border-color: #6b7280; /* slightly darker border on focus */
}

.file-input::placeholder {
    color: #9ca3af; /* placeholder color */
}

#file-input-form {
    max-width: 50%;
}

#file-input-submit {
    margin-top: 20px;
    background-color: #007bff;
    color: white;
    border: none;
    padding: 12px 24px;
    border-radius: 20px;
    font-size: 16px;
    font-weight: 500;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    transition: transform 0.2s, box-shadow 0.2s;
    cursor: pointer;
    outline: none;
}

@media (max-width: 768px) {
    #file-input-form {
        max-width: 100%;
    }
}

@media (max-width: 1024px) {
    #file-input-form {
        max-width: 100%;
    }
}

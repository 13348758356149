#add-data-grid {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 20px;
    margin-bottom: 20px;
    max-width: 1536px;
}

.add-card-name {
    font-size: 1.1rem;
}

@media (min-width: 640px) {
    #add-data-grid {
        grid-template-columns: 1fr;
    }

    .add-card-name {
        font-size: 0.8rem;
    }
}

@media (min-width: 768px) {
    #add-data-grid {
        grid-template-columns: repeat(2, 1fr);
    }

    .add-card-name {
        font-size: 0.9rem;
    }
}

@media (min-width: 1024px) {
    #add-data-grid {
        grid-template-columns: repeat(3, 1fr);
    }
}

@media (min-width: 1280px) {
    #add-data-grid {
        grid-template-columns: repeat(5, 1fr);
    }

    .add-card-name {
        font-size: 0.9rem;
    }
}

@media (min-width: 1536px) {
    #add-data-grid {
        grid-template-columns: repeat(5, 1fr);
    }

    .add-card-name {
        font-size: 1.1rem;
    }
}
